@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('./assets/fonts/Satoshi/WEB/css/satoshi.css') */

@layer base {
  @font-face {
    font-family: 'Satoshi';
    src: url(./assets/fonts/Satoshi/TTF/Satoshi-Variable.ttf) format('tff');
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
